import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
//import authRoleExamplesConfigs from 'app/main/auth/authRoleExamplesConfigs';
import authRoleConfigs from 'app/main/auth/authRoleConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';
import DocumentationConfig from 'app/main/documentation/DocumentationConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import RegisterConfig from 'app/main/register/RegisterConfig';
import UserInterfaceConfig from 'app/main/user-interface/UserInterfaceConfig';
import React from 'react';
import { Redirect } from 'react-router-dom';

// sections
import sectionsConfigs from "app/main/sections/sectionsConfigs.js";

const routeConfigs = [
	...appsConfigs,
	...pagesConfigs,
	//...authRoleExamplesConfigs,
	...authRoleConfigs,
	UserInterfaceConfig,
	DocumentationConfig,
	LogoutConfig,
	LoginConfig,
	RegisterConfig,
	LogoutConfig,
	CallbackConfig,
	...sectionsConfigs
];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
	{
		path: '/',
    exact: true,
    //auth: ['user-only-reservations'],
		component: () => <Redirect to="/" />
	},
	// {
	// 	path: '/',
  //   exact: true,
  //   auth: ['admin', 'staff', 'user'],
	// 	component: () => <Redirect to="/sections/company/info" />
	// },
  /*{
    path: '/',
    exact: true,
    auth: null,
    component: () => <Redirect to="/guest" />
  },*/
	{
		path: '/analytics',
		exact: true,
		component: () => <Redirect to="/apps/dashboards/analytics" />
	},
	{
		component: () => <Redirect to="/pages/errors/error-404" />
	}
];

export default routes;

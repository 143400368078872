import { authRoles } from 'app/auth';
import i18n from 'i18n';
const t = (...args) => i18n.t(...args);

const navigationConfig = [
	{
		id: 'applications',
		// title: 'Applications',
		// translate: 'APPLICATIONS',
		// COMPANYINFO: "Company Info",
		// INFO: "Info",
		// DETAILS: "Details",
		// USERS: "Users",
		type: 'group',
		icon: 'apps',
		children: [
			/*{
				id: 'only-guest-navigation-item',
				title: 'Nav item only for Guest',
				type: 'item',
				auth: authRoles.onlyGuest, // ['guest']
				url: '/guest', //'/auth/guest-role',
				icon: 'person', //'verified_user'
		  },*/
      {
				id: 'company',
				title: 'Company',
				translate: t('COMPANY'),
				type: 'collapse',
				auth: authRoles.user,
				icon: 'business',
				url: '/sections/company/info',
				children: [
					{
						id: 'company-info',
						title: 'Info',
						translate: t('INFO'),
						type: 'item',
						auth: authRoles.user,
						icon: 'info',
						url: '/sections/company/info'
					},
          {
            id: 'company-shops',
            title: 'Shops',
            translate: t('SHOPS'),
            type: 'item',
						auth: authRoles.user,
            icon: 'work',
            url: '/sections/company/shops'
          },
/*
					{
						id: 'company-details',
						title: 'Details',
						translate: t('DETAILS'),
						type: 'item',
						auth: authRoles.user,
						icon: 'work',
						url: '/sections/company/details'
					},
					{
						id: 'company-users',
						title: 'Users',
						translate: t('USERS'),
						type: 'item',
						auth: authRoles.user,
						icon: 'account_circle',
						url: '/sections/company/users'
					}
*/
			  ]
			},
			{
				id: 'associate-users',
				title: 'Users',
				translate: t('USERS'),
				type: 'item',
				auth: authRoles.user,
				icon: 'account_circle',
				url: '/sections/associateusers'
			},
    	{
				id: 'articles',
				title: 'Articles',
				translate: t('ARTICLES'),
				type: 'item',
				auth: authRoles.user,
				icon: 'shopping_cart',
				url: '/sections/articles',
			},
			{
				id: 'reservations',
				title: 'Reservations',
				translate: t('RESERVATIONS'),
				type: 'item',
				auth: authRoles.userOnlyReservations,
				icon: 'alarm_on',
				url: '/sections/reservations',
			},
      {
        id: 'accounting',
        title: 'Accounting',
        translate: t('ACCOUNTING'),
        type: 'item',
				auth: authRoles.user,
        icon: 'euro_symbol',
        url: '/sections/accounting/balance',
      },
      {
        id: 'concierge',
        title: 'Concierge',
        translate: t('CONCIERGE'),
        type: 'item',
				auth: authRoles.userConcierge,
        icon: 'room_service',
        url: '/sections/concierge',
      },
		]
	},
];

export default navigationConfig;

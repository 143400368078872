// Internet Explorer 11 requires polyfills and partially supported by this project.
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-muli';
import './i18n';
import './react-chartjs-2-defaults';
import './styles/index.css';
import App from 'app/App';
import * as serviceWorker from './service-worker';
//import configureServiceWorkerStore from './service-worker-store';
//import { SW_INIT, SW_UPDATE } from './service-worker-types';

import i18n from 'i18n';
const t = (...args) => i18n.t(...args);

ReactDOM.render(<App />, document.getElementById('root'));

const sw = new URLSearchParams(window.location.search).get('sw'); // TODO: REMOVE WHEN SW FULLY OPERATIVE... Using service worker only if requested on command line with ...?sw=1
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
if (!sw) {
  console.log('SW: unregistering...');
  serviceWorker.unregister();
} else {
  console.log('SW: registering...');
  serviceWorker.register({
    onSuccess: ( registration => {
      //serviceWorkerStore.dispatch({ type: SW_INIT });
      console.log('SW: onSuccess', registration);
    }),
    onUpdate: (registration => {
      console.log('SW: onUpdate', registration);
      //serviceWorkerStore.dispatch({ type: SW_UPDATE, payload: registration });
      if (window.confirm(t('New content is available.\nDo you want to use it now?'))) {
        window.location.reload();
      }
    }),
    onWaiting: (registration => {
      console.log('SW: onWaiting', registration);
      //window.alert('New content is waiting....');
/*
        if (window.confirm(t('New content is waiting.\nDo you want to use it now?'))) {
          window.location.reload();
      }
*/
    })
  });
}

/*
const config = {};
config.onSuccess = () => {
  serviceWorkerStore.dispatch({ type: SW_INIT });
}
config.onUpdate = (registration => {
  //window.postMessage('SERVICE_WORKER_UPDATE_READY', '*', registration);
  //alert(t('New content is available and will be used when all tabs for this page are closed or reloaded'));
  if (window.confirm(t('New content is available.\nDo you want to use it now?'))) {
    //registration.waiting.postMessage("skipWaiting");
    window.location.reload();
  }
  console.warn('serviceWorker - onUpdate - registration:', registration);
  serviceWorkerStore.dispatch({ type: SW_UPDATE, payload: registration });
});
config.onWaiting = (registration => { // TODO: simply call `config.onUpdate(registration)`
//window.postMessage('SERVICE_WORKER_UPDATE_WAITING', '*', registration);
//return config.onUpdate(registration);
  alert(t('New content is waiting and will be used when all tabs for this page are closed or reloaded'));
  // if (window.confirm('bis ' + t('New content is available.\nDo you want to use it now?'))) {
  //   //registration.waiting.postMessage("skipWaiting");
  //   window.location.reload();
  // }
  // console.warn('serviceWorker - onWaiting - registration:', registration);
});
config.onSuccess = (registration => {
  console.warn('serviceWorker - onSuccess - registration:', registration);
});
serviceWorker.register(config);
*/
import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import React from 'react';
import i18n from 'i18n';
const t = (...args) => i18n.t(...args);

const useStyles = makeStyles(theme => ({
	layoutRoot: {}
}));

function GuestRole() {
	const classes = useStyles();

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			header={
				<div className="p-24 flex items-center">
					<Typography className="h1">{t("Guest landing page")}</Typography>
				</div>
			}
			content={
				<div className="p-96 text-center">
					<Typography className="h2 mb-24">
						{t("You see this page because you have not logged in.")}
  				</Typography>
					<Typography className="h2 mb-24">
						{t("Please")} <Link to="/login">login</Link>, {t("or contact")} <a href="https://www.mypass.cc">MyPass</a> {t("to register")}.
	  			</Typography>
		  	</div>
			}
		/>
	);
}

export default GuestRole;
import { showMessage } from 'app/store/actions/fuse';
import * as authActions from 'app/auth/store/actions';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { validateEmail } from 'libs/utils';

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText
  }
}));

function ForgotPassword() {
  const classes = useStyles();
  const { form, handleChange, resetForm } = useForm({
    email: ''
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function isFormValid() {
    return form.email.length > 0;
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    resetForm();
    dispatch(authActions.sendResetPasswordEmail(form.email));
    dispatch(showMessage({ message: t("A mail message was sent to the address you specified with the instructions to reset your password") }));
    history.push({
      pathname: '/login' // was '/'
    });
  }

  return (
    <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0')}>
      <div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
        <FuseAnimate animation="transition.expandIn">
          <img className="w-128 mb-32" src="assets/images/logos/logo.png" alt="logo" />
        </FuseAnimate>

        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <Typography variant="h3" color="inherit" className="font-light">
            {t('Welcome to the MARKETPLACE!')}
					</Typography>
        </FuseAnimate>

        <FuseAnimate delay={400}>
          <Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16">
            {t('A portal to manage your shops, your reservations and your sales')}
					</Typography>
        </FuseAnimate>
      </div>

      <FuseAnimate animation={{ translateX: [0, '100%'] }}>
        <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
          <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
            <Typography variant="h6" className="md:w-full mb-32">
              {t('RECOVER YOUR PASSWORD')}
						</Typography>

            <form
              name="recoverForm"
              noValidate
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit}
            >
              <TextField
                className="mb-16"
                error={!validateEmail(form.email)}
                required
                label={t("Email")}
                autoFocus
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />

              <Button
                variant="contained"
                color="primary"
                className="w-224 mx-auto mt-16"
                aria-label="Reset"
                disabled={!isFormValid()}
                type="submit"
              >
                {t('SEND RESET LINK')}
							</Button>
            </form>

            <div className="flex flex-col items-center justify-center pt-32 pb-24">
              <Link className="font-medium" to="/login">{/*to="/pages/auth/login-2"*/}
								{t("Go back to login")}
							</Link>
            </div>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  );
}

export default ForgotPassword;
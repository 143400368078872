import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import config from 'config';

/* eslint-disable camelcase */

class NetworkService {
	isLocal = () => {
		return (
			window.location.hostname === "localhost" ||
			window.location.hostname === "127.0.0.1" ||
			window.location.hostname.match(/^192\.168\.\d+\.\d+$/)
		);
	}

	isProduction = () => {
		return process.env.NODE_ENV === 'production' && !window.location.href.match(/\.test\./);
	}

	getAPIUrl = (endpoint) => {
		/* eslint-disable no-useless-concat */
		return this.isLocal() ?
      (config.API_URL + config.API_PATH_DEVELOPMENT + "/" + endpoint + (endpoint ? "?" : "")) : // local development
			(config.PROXY_URL + "?" + "url=" + config.API_URL +
				(this.isProduction() ? config.API_PATH_PRODUCTION : config.API_PATH_DEVELOPMENT) +
				"/" + endpoint + (endpoint ? "&" : "")
			) // remote development
		;
	}

	getWidgetUrl = () => {
		return this.isProduction() ?
      config.WIDGET_URL_PRODUCTION :
      config.WIDGET_URL_DEVELOPMENT
		;
	}

  /**
	 * a wrapper around MyPass API fetch
	 */
	fetch = (endpoint, method, data) => {
		return new Promise((resolve, reject) => {
			if (!endpoint) {
				reject(new Error('no endpoint'));
			}
			if (!method) {
				method = "GET"; // default method id GET
			}
			if (!data) {
				data = {}; // default parameters is empty object
			}
			
			data.app = data.app ? data.app : require('../../../../package.json').name;
			data.platform = data.platform ? data.platform : config.API_PLATFORM;
			data.version = data.version ? data.version : config.API_VERSION;
      data.portal = data.portal ? data.portal : config.API_PORTAL;
			data.language = data.language ? data.language : localStorage.getItem('i18nextLng');
      data.language = data.language ? data.language : config.fallbackLanguage;

      let searchParams = (method === 'GET' ? new URLSearchParams(data).toString() : '');
      //searchParams = searchParams ? '?' + searchParams : searchParams; 
		  const settings = {
				"url": this.getAPIUrl(endpoint) + searchParams,
				"data": (method === 'GET') ? {} : data,
				"email": data.email,
				"method": method,
				"timeout": config.API_TIMEOUT,
				"headers": {
					"Content-Type": "application/json",
					"Cache-Control": "no-cache", // use cacheAdapterEnhancer
				},
				"adapter": cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
				"useCache": false,
      };
      console.log('%c → fetch request for ' + endpoint + ':', 'color: darkblue; font-style: italic;', settings);
			axios(settings)
				.then(response => {
          console.log('%c ← fetch response for ' + endpoint + ':', 'color: darkblue; font-style: italic;', response);
					if (!response.data) {
						return reject('no data in response');
					}
/*
          if (response.status !== 'success') {
            if (response.message) {
              console.error('server responded with failure status:', response.status, response.message);
              return reject(response.message);
            } else {
              return reject(response.status); // this should not happen
            }
          }
*/
          if (response.status !== 200 && response.data.status !== 'success') {
            if (response.data.message) {
              console.error('server responded with failure status:', response.data.status, response.data.message);
              return reject(response.data.message);
            } else {
              return reject(response.data.status); // this should not happen
            }
					}
					resolve(response);
				})
				.catch(error => {
          console.error('server error:', error);
  				reject(error)
				})
			;
    });
	};

}

const instance = new NetworkService();

export default instance;
import * as Actions from '../actions';

const initialState = {
	success: false,
	error: {
		username: null,
		password: null
	}
};

const login = (state = initialState, action) => {
	switch (action.type) {
    case Actions.SEND_RESET_PASSWORD_EMAIL_SUCCESS: {
      return {
        ...initialState,
        success: true
      };
    }
    case Actions.SEND_RESET_PASSWORD_EMAIL_ERROR: {
      return {
        success: false,
        error: action.payload
      };
    }
		default: {
			return state;
		}
	}
};

export default login;
import React from 'react';
import { Redirect } from 'react-router-dom';

const ArticlesConfig = {
	settings: {
		layout: {}
	},
  routes: [ // order matters here...
		{
      path: '/sections/articles/products/:productId/:tabId?',
			component: React.lazy(() => import('./product/Product'))
		},
		{
			path: '/sections/articles/products',
			component: React.lazy(() => import('./products/Products'))
		},
		{
			path: '/sections/articles/products-prices/:productId/:priceId',
			component: React.lazy(() => import('./product/price/ProductPrice'))
		},
		{
			path: '/sections/articles/products-prices/:productId/',
			component: React.lazy(() => import('./product/Product'))
		},
		{
			path: '/sections/articles/products-prices',
			component: React.lazy(() => import('./products/Products'))
		},
		{
			path: '/sections/articles/orders/:orderId',
			component: React.lazy(() => import('./order/Order'))
		},
		{
			path: '/sections/articles/orders',
			component: React.lazy(() => import('./orders/Orders'))
		},
		{
			path: '/sections/articles',
			component: () => <Redirect to="/sections/articles/products" />
		}
	]
};

export default ArticlesConfig;

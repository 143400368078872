import Typography from '@material-ui/core/Typography';
import FuseSeparator from '@fuse/core/FuseSeparator';
import React from 'react';
import { useSelector } from 'react-redux';

function FuseTitle(props) {
	const user = useSelector(({ auth }) => auth.user);
	const settings = useSelector(({ fuse }) => fuse.settings.current);
	const currentRoleIndex = typeof settings.currentRoleIndex !== 'undefined' ? settings.currentRoleIndex : (localStorage.getItem('currentRoleIndex') || 0);
  
  const merchant_name = user && user.roles && user.roles[currentRoleIndex] ? user.roles[currentRoleIndex].merchant_name : null;
  //const event_name = user && user.roles && user.roles[currentRoleIndex] ? user.roles[currentRoleIndex].event_name : null;
  const event_description = user && user.roles && user.roles[currentRoleIndex] ? user.roles[currentRoleIndex].event_description : null;

	return (
		<div className="flex flex-1 flex-col items-center justify-center">
			<Typography className="text-16" color="textSecondary">
        {merchant_name}
        {merchant_name && event_description && <FuseSeparator />}
        {event_description}
			</Typography>
		</div>
	);
}

FuseTitle.propTypes = {
};

FuseTitle.defaultProps = {
};

export default FuseTitle;

import React from 'react';
import { Redirect } from 'react-router-dom';

const CompanyConfig = {
	settings: {
		layout: {}
	},
  routes: [ // order matters here...
		{
			path: '/sections/company/info/',
			component: React.lazy(() => import('./info/Company'))
		},
    {
      path: '/sections/company/create/',
      component: React.lazy(() => import('./create/CompanyCreate'))
    },
    {
      path: '/sections/company/shops/:shopId/:tabId?',
      component: React.lazy(() => import('./shops/Shop'))
    },
		{
			path: '/sections/company/shops/',
			component: React.lazy(() => import('./shops/Shops'))
		},
/*
		{
			path: '/sections/company/users/',
			component: React.lazy(() => import('./users/Users'))
		},
*/
		{
			path: '/sections/company',
			component: () => <Redirect to="/sections/company/info" />
		}
	]
};

export default CompanyConfig;
import { TextFieldFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import * as authActions from 'app/auth/store/actions';
import NetworkService from 'app/services/networkService';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function JWTMypassLoginTab(props) {
	const dispatch = useDispatch();
	const login = useSelector(({ auth }) => auth.login);

  const passwordMinLength = 4;

	const [isFormValid, setIsFormValid] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const formRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
		if (login.error && (login.error.email || login.error.password)) {
			formRef.current.updateInputsWithError({
				...login.error
			});
			disableButton();
		}
	}, [login.error]);

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function handleSubmit(model) {
		dispatch(authActions.submitMypassLogin(model));
	}

	return (
		<div className="w-full">
			<Formsy
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				ref={formRef}
				className="flex flex-col justify-center w-full"
			>
				<TextFieldFormsy
					className="mb-16"
					type="text"
					name="email"
					label={t("Username/Email")}
					//value="admin"
					validations={{
						minLength: 4
					}}
					validationErrors={{
            minLength: t('Minimum username/email length is {{count}}', passwordMinLength),
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Icon className="text-20" color="action">
									email
								</Icon>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				<TextFieldFormsy
					className="mb-16"
					type="password"
					name="password"
					label={t("Password")}
					//value="admin"
					validations={{
						minLength: 4
					}}
					validationErrors={{
            minLength: t('Minimum password length is {{count}}', passwordMinLength)
					}}
					InputProps={{
						className: 'pr-2',
						type: showPassword ? 'text' : 'password',
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)}>
									<Icon className="text-20" color="action">
										{showPassword ? 'visibility' : 'visibility_off'}
									</Icon>
								</IconButton>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="w-full mx-auto mt-16 normal-case"
					aria-label="LOG IN"
					disabled={!isFormValid}
					value="legacy"
				>
					{t('Login')}
				</Button>

        <Link className="w-full mx-auto mt-16 normal-case text-right font-small" to="/forgot-password">
          {t('Forgot Password?')}
				</Link>

				<div className="my-24 flex items-center justify-center opacity-25">
					<Divider className="w-32" />
          <span className="mx-8 font-bold">{t('OR')}</span>
					<Divider className="w-32" />
				</div>

				<div className="flex flex-col justify-center w-full items-center justify-center">
					<Button
						variant="contained"
						color="secondary"
						size="small"
						className="normal-case w-192 mb-8"
						disabled={true}
					>
            {t('Log in with Google')}
					</Button>

					<Button
						variant="contained"
						color="primary"
						size="small"
						className="normal-case w-192"
						disabled={true}
					>
            {t('Log in with Facebook')}
					</Button>
        </div>

			</Formsy>

			{!NetworkService.isProduction() && (
				<div className="flex flex-col items-center pt-24">
					<Typography className="text-14 font-600 py-8">{t('Credentials')}</Typography>

					<Divider className="mb-16 w-256" />

					<table className="text-left w-256">
						<thead>
							<tr>
								<th>
									<Typography className="font-600" color="textSecondary">
                    {t('Role')}
									</Typography>
								</th>
								<th>
									<Typography className="font-600" color="textSecondary">
                    {t('Username')}
									</Typography>
								</th>
								<th>
									<Typography className="font-600" color="textSecondary">
                    {t('Password')}
									</Typography>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<Typography>Admin</Typography>
								</td>
								<td>
									<Typography>market.admin@mypass.cc</Typography>
								</td>
								<td>
									<Typography>1...8</Typography>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>Merchant</Typography>
								</td>
								<td>
									<Typography>market.merchant@mypass.cc</Typography>
								</td>
								<td>
									<Typography>1...8</Typography>
								</td>
							</tr>
							<tr>
								<td>
									<Typography>Guest</Typography>
								</td>
								<td>
									<Typography>any</Typography>
								</td>
								<td>
									<Typography>any</Typography>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
	    )}

		</div>
	);
}

export default JWTMypassLoginTab;
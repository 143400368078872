import moment from 'moment';
import 'moment/min/locales';
//import zipsProvinces from '../zips-provinces';
import comuniProvinces from '../comuni-provinces';

/**
 * Format a currencty field
 * 
 * number is the number to be formatted.
 * details are: {
 *   currency,
 *   decimalPlaces,
 *   decimalSeparator,
 *   thousandSeparator
 * }
 */
export function formatMoney(number, details = {}) {
  number = parseFloat(number);
  const currency = typeof details.currency === "undefined" ? "€" : details.currency;
  const decimalPlaces = isNaN(details.ecimalPlaces = Math.abs(details.decimalPlaces)) ? 2 : details.decimalPlaces;
  const decimalSeparator = typeof details.decimalSeparator === "undefined" ? "." : details.decimalSeparator;
  const thousandSeparator = typeof details.housandSeparator === "undefined" ? "," : details.thouthousandSeparatorSep;
  const sign = number < 0 ? "-" : "";
  let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decimalPlaces)));
  let j = i.length; j = i.length > 3 ? j % 3 : 0;

  return currency + " " + sign +
    (j ? i.substr(0, j) + thousandSeparator : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thousandSeparator) +
    (decimalPlaces ? decimalSeparator + Math.abs(number - i).toFixed(decimalPlaces).slice(2) : "");
}

/**
 * Format a date only field according to current locale format
 * 
 * Input: date (YYYY-MM-DD HH:MM:SS) and locale
 * Output: date in format MM/DD/YYYY or DD/MM/YYYY, or whatever the long date format is for specified locale
 */
export function localeFormatDate(date, locale) {
  date = (date && typeof date === 'string') ? date : null;
  moment.locale(locale);
  moment.updateLocale('it', {
    longDateFormat : {
      LTYHM: 'DD/MM/YYYY HH:mm', // new format for token here
      L: "DD/MM/YYYY",
    }
  });
  const format = moment.localeData().longDateFormat('LTYHM');
  return moment(date).format(format);
/*
  date = (date && typeof date === 'string') ? date.split(' ')[0] : null;
  moment.locale(locale);
  const format = moment.localeData().longDateFormat('L');
  return moment(date).format(format);
*/
}

/**
 * Formats a csv string of digits in the format /[1-7][,1-7]{0-6} as a list of week days with specified locale
 */
export function localeFormatWeekDays(weekdaysCSV, locale) {
  if (!weekdaysCSV) {
    weekdaysCSV = "1,2,3,4,5,6,7";
  }
  const weekdaysArray = weekdaysCSV.split(",").map(day => parseInt(day));
  const weekdays = weekdaysArray.filter(day => !isNaN(day)).map(day => 
    moment().locale(locale).isoWeekday(day).format('ddd')
  );
  return weekdays.join("|");
}

/**
 * Get date format according to current locale
 * 
 * Input: locale
 * Output: MM/DD/YYYY or DD/MM/YYYY, or whatever the long date format is for specified locale
 */
export function localeDateFormat(locale) {
  moment.locale(locale);
  const format = moment.localeData().longDateFormat('L');
  return format;
}

/**
 * Validate email formally
 * 
 * Input: email
 * Output: true / false
 */
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!re.test(String(email).toLowerCase());
}

/**
 * Validate email formally (an empty email is validated)
 * 
 * Input: email
 * Output: true / false
 */
export function validateEmailOrEmpty(email) {
  return !email ? true : validateEmail(email);
}

/**
 * Validate phone formally
 * 
 * Input: phone
 * Output: true / false
 */
export function validatePhone(phone) {
  return !!phone; // always valid if it is not null or undefined
  // const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im; // this looks too restrictive (+39-333-6480983 is not validated)
  // return !!re.test(String(phone));
}

/**
 * Validate phone formally (an empty phone is validated)
 * 
 * Input: phone
 * Output: true / false
 */
export function validatePhoneOrEmpty(phone) {
  return !phone ? true : validatePhone(phone); // always valid if it is not null or undefined
}

/**
 * Validate url formally
 *
 * Input: url
 * Output: true / false
 */
export function validateUrl(url) {
  const re = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  ;
  return !!re.test(String(url));
}

/**
 * Validate url formally (an empty url is validated)
 *
 * Input: url
 * Output: true / false
 */
export function validateUrlOrEmpty(url) {
  return !url ? true : validateUrl(url);
}

/**
 * Verifica validità formale del codice fiscale italiano.
 *
 * @param string cf   Codice fiscale da controllare.
 * @return boolean    True vuota se il codice è corretto.
 *                    False altrimenti.
 */
export function validateCodiceFiscale(cf) {
  cf = cf.toUpperCase();
  if (!cf) {
    return false;
  }
  if (!/^[0-9A-Z]{16}$/.test(cf)) {
    return false;
  }
  let map = [
    1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17,
    19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23
  ];
  var s = 0;
  for (var i = 0; i < 15; i++) {
    let c = cf.charCodeAt(i);
    if (c < 65) {
      c = c - 48;
    } else {
      c = c - 55;
    }
    if (i % 2 === 0) {
      s += map[c];
    } else {
      s += c < 10 ? c : c - 10;
    }
  }
  let atteso = String.fromCharCode(65 + s % 26);
  if (atteso !== cf.charAt(15)) {
    return false;
  }
  return true;
}

/**
 * Verifica validità formale del codice fiscale italiano (un codice vuoto è validato).
 *
 * @param string cf   Codice fiscale da controllare.
 * @return boolean    True vuota se il codice è corretto.
 *                    False altrimenti.
 */
export function validateCodiceFiscaleOrEmpty(cf) {
  return !cf ? true : validateCodiceFiscale(cf);
}

/**
 * Verifica validità formale della partita IVA italiana.
 *
 * @param string pi   Partita IVA da controllare.
 * @return boolean    True vuota se il codice di controllo è corretto.
 *                    False altrimenti.
 */
export function validatePartitaIva(pi) {
  if (!pi) {
    return false;
  }
  if (!/^[0-9]{11}$/.test(pi)) {
    return false;
  }
  var s = 0;
  for (let i = 0; i <= 9; i += 2) {
    s += pi.charCodeAt(i) - '0'.charCodeAt(0);
  }
  for (let i = 1; i <= 9; i += 2) {
    var c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
    if (c > 9) {
      c = c - 9;
    }
    s += c;
  }
  let atteso = (10 - s % 10) % 10;
  if (atteso !== pi.charCodeAt(10) - '0'.charCodeAt(0)) {
    return false;
  }
  return true;
};

/**
 * Verifica validità formale della partita IVA italiana (un codice vuoto è validato).
 *
 * @param string pi   Partita IVA da controllare.
 * @return boolean    True vuota se il codice è corretto.
 *                    False altrimenti.
 */
export function validatePartitaIvaOrEmpty(pi) {
  return !pi ? true : validatePartitaIva(pi);
}

/**
 * Verifica validità del codice SDI italiano.
 * 
 * @param string sdi  Codice SDI da controllare.
 * @return boolean    True se il codice è formalmente corretto.
 *                    False altrimenti.
 */
export function validateSDICode(sdi) {
  return sdi.length === 7;
}

/**
 * Verifica validità del codice SDI italiano (un codice vuoto è validato).
 *
 * @param string sdi  Codice SDI da controllare.
 * @return boolean    True vuota se il codice è corretto.
 *                    False altrimenti.
 */
export function validateSDICodeOrEmpty(pi) {
  return !pi ? true : validateSDICode(pi);
}

/**
 * Verifica validità formale del codice IBAN.
 * 
 * @param string iban Codice IBAN da controllare.
 * @return boolean    True se il codice è formalmente corretto.
 *                    False altrimenti.
 */
export function validateIBANCode(iban) {
  let CODE_LENGTHS = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
  };
  iban = String(iban).toUpperCase().replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits);

  function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }
}

/**
 * Verifica validità formale del codice IBAN (un codice vuoto è validato).
 *
 * @param string iban Codice IBAN da controllare.
 * @return boolean    True se il codice è formalmente corretto.
 *                    False altrimenti.
 */
export function validateIBANCodeOrEmpty(iban) {
  return !iban ? true : validateIBANCode(iban);
}

/**
 * Verifica validità formale dellla password.
 * 
 * @param string pass Password da controllare.
 * @return boolean    True se la password è formalmente corretta.
 *                    False altrimenti.
 */
export function validatePassword(pass) {
  return pass.length >= 8;
}

/**
 * Verifica validità formale della password (un codice vuoto è validato).
 *
 * @param string iban Password da controllare.
 * @return boolean    True se la password è formalmente corretta.
 *                    False altrimenti.
 */
export function validatePasswordOrEmpty(pass) {
  return !pass ? true : validatePassword(pass);
}

/**
 * Exclude a property from object
 */
export const exclude = (object, prop) => {
  return Object.keys(object).reduce((obj, key) => { if (key !== prop) obj[key] = object[key]; return obj}, {});
}

// export const getProvinceFromZip = (zip) => {
//   if (zipsProvinces[zip]) return zipsProvinces[zip];
//   const zip1 = String(zip).replace(/\d{1}$/, 'x'); // handle NNNNx zip codes
//   if (zipsProvinces[zip1]) return zipsProvinces[zip1];
//   const zip2 = String(zip).replace(/\d{2}$/, 'xx'); // handle NNNxx zip codes
//   if (zipsProvinces[zip2]) return zipsProvinces[zip2];
//   return ''; // handle case of zip not found
// }

export const getProvinceFromComune = (comune) => {
  //console.log('comune:', comune);
  //console.log('comuniProvinces:', comuniProvinces);
  if (comuniProvinces[comune]) return comuniProvinces[comune];
  return ''; // handle case of comune not found
}

export const getAddressFromAddressFull = (addressFull) => {
  const address = addressFull ? addressFull.replace(/(\s*,?\s\d+\s?(?:bis|ter|[a-zA-Z])?)$/, '') : addressFull;
  return address;
}

export const getStreetNumberFromAddressFull = (addressFull) => {
  const streetNumber = addressFull ? addressFull.replace(/(.*?),?\s?(\d+\s?(?:bis|ter|[a-zA-Z])?)?$/, "$2") : addressFull;
  return streetNumber;
}
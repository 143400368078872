import React from 'react';
import PropTypes from 'prop-types';

function FuseSeparator(props) {
	return (
    <span style={props.style} />
	);
}

FuseSeparator.propTypes = {
  style: PropTypes.object,
};

FuseSeparator.defaultProps = {
  style: {
    borderWidth: 1,
    borderColor: '#dedede',
    height: '50%',
    marginLeft: 10,
    marginRight: 10,
  }
};

export default FuseSeparator;
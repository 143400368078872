import React from 'react';
//import { Redirect } from 'react-router-dom';

const AssociateusersConfig = {
	settings: {
		layout: {}
	},
  routes: [ // order matters here...
		{
      path: '/sections/associateuser/:associateuserId/:tabId?',
      component: React.lazy(() => import('./Associateuser'))
    },
		{
			path: '/sections/associateusers/',
			component: React.lazy(() => import('./Associateusers'))
		},
	]
};

export default AssociateusersConfig;
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as Actions from 'app/store/actions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import config from 'config.js';
import i18n from 'i18n';
const t = (...args) => i18n.t(...args);

function LanguageSwitcher(props) {
	const dispatch = useDispatch();

	const theme = useTheme();
	const { i18n } = useTranslation();
	const [menu, setMenu] = useState(null);

  const currentLng = config.languages.find(lng => lng.id === i18n.language.substr(0, 2)) || config.fallbackLanguage; // use only the language parte of the language-country string

	const userMenuClick = event => {
		setMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setMenu(null);
	};

	function handleLanguageChange(lng) {
		const newLangDir = i18n.dir(lng.id);

		/**
     * Change Language
     */
		i18n.changeLanguage(lng.id);

		/**
     * If necessary, change theme direction
     */
		if (newLangDir !== theme.direction) {
			dispatch(Actions.setDefaultSettings({ direction: newLangDir }));
		}

		userMenuClose();

		window.location.reload(/*false*/); // TODO: do we need this to re-render new language strings?
	}

	return (
		<>
			<Button className="h-64 w-64" onClick={userMenuClick}>
        {currentLng.flag}
				{/*<img
					className="mx-4 min-w-20"
					src={`assets/images/flags/${currentLng.flag}.png`}
					alt={currentLng.name}
        />*/}
				<Typography className="mx-4 font-600">{currentLng.id}</Typography>
			</Button>

			<Popover
				open={Boolean(menu)}
				anchorEl={menu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{config.languages.map(lng => (
					<MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
						<ListItemText className="min-w-40">
              {lng.flag}
						</ListItemText>
						<ListItemText primary={t(lng.name)} />
					</MenuItem>
				))}
			</Popover>
		</>
	);
}

export default LanguageSwitcher;
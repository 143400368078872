import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import * as userActions from 'app/auth/store/actions';
// import auth0Service from 'app/services/auth0Service';
// import firebaseService from 'app/services/firebaseService';
// import jwtService from 'app/services/jwtService';
import jwtMypassService from 'app/services/jwtMypassService';
import * as Actions from 'app/store/actions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import i18n from 'i18n';
//const t = (...args) => i18n.t(...args);

class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		return Promise.all([
			// Comment the lines which you do not use
			// this.firebaseCheck(),
			// this.auth0Check(),
			// this.jwtCheck()
			this.jwtMypassCheck()
		]).then(() => {
			this.setState({ waitAuthCheck: false });
		});
	}

	jwtMypassCheck = () =>
		new Promise(resolve => {
			jwtMypassService.on('onAutoLogin', () => {
				//this.props.showMessage({ message: t('Logging in with JWT Mypass') });
				//console.log('Logging in with JWT Mypass');

				/**
				 * Sign in and retrieve user data from Api
				 */
				jwtMypassService
					.signInWithToken()
					.then(user => {
						this.props.setUserData(user);

						resolve();

						//this.props.showMessage({ message: 'Logged in with JWT Mypass' });
						console.info('Logged in with JWT Mypass');
					})
					.catch(error => {
						this.props.showMessage({ message: error });
            console.error('Error logging in with JWT Mypass:', error);

						resolve();
					});
			});

			jwtMypassService.on('onAutoLogout', message => {
				if (message) {
					this.props.showMessage({ message });
				}

				this.props.logout();

				resolve();
			});

			jwtMypassService.on('onNoAccessToken', () => {
				resolve();
			});

      jwtMypassService.on('onServiceError', message => {
        if (message) {
          this.props.showMessage({ message });
        }
        resolve();
      });

			jwtMypassService.init();

			return Promise.resolve();
		});

	// jwtCheck = () =>
	// 	new Promise(resolve => {
	// 		jwtService.on('onAutoLogin', () => {
	// 			this.props.showMessage({ message: 'Logging in with JWT' });

	// 			/**
	// 			 * Sign in and retrieve user data from Api
	// 			 */
	// 			jwtService
	// 				.signInWithToken()
	// 				.then(user => {
	// 					this.props.setUserData(user);

	// 					resolve();

	// 					this.props.showMessage({ message: 'Logged in with JWT' });
	// 				})
	// 				.catch(error => {
	// 					this.props.showMessage({ message: error });

	// 					resolve();
	// 				});
	// 		});

	// 		jwtService.on('onAutoLogout', message => {
	// 			if (message) {
	// 				this.props.showMessage({ message });
	// 			}

	// 			this.props.logout();

	// 			resolve();
	// 		});

	// 		jwtService.on('onNoAccessToken', () => {
	// 			resolve();
	// 		});

	// 		jwtService.init();

	// 		return Promise.resolve();
	// 	});

	// auth0Check = () =>
	// 	new Promise(resolve => {
	// 		auth0Service.init(success => {
	// 			if (!success) {
	// 				resolve();
	// 			}
	// 		});

	// 		if (auth0Service.isAuthenticated()) {
	// 			this.props.showMessage({ message: 'Logging in with Auth0' });

	// 			/**
	// 			 * Retrieve user data from Auth0
	// 			 */
	// 			auth0Service.getUserData().then(tokenData => {
	// 				this.props.setUserDataAuth0(tokenData);

	// 				resolve();

	// 				this.props.showMessage({ message: 'Logged in with Auth0' });
	// 			});
	// 		} else {
	// 			resolve();
	// 		}

	// 		return Promise.resolve();
	// 	});

	// firebaseCheck = () =>
	// 	new Promise(resolve => {
	// 		firebaseService.init(success => {
	// 			if (!success) {
	// 				resolve();
	// 			}
	// 		});

	// 		firebaseService.onAuthStateChanged(authUser => {
	// 			if (authUser) {
	// 				this.props.showMessage({ message: 'Logging in with Firebase' });

	// 				/**
	// 				 * Retrieve user data from Firebase
	// 				 */
	// 				firebaseService.getUserData(authUser.uid).then(
	// 					user => {
	// 						this.props.setUserDataFirebase(user, authUser);

	// 						resolve();

	// 						this.props.showMessage({ message: 'Logged in with Firebase' });
	// 					},
	// 					error => {
	// 						resolve();
	// 					}
	// 				);
	// 			} else {
	// 				resolve();
	// 			}
	// 		});

	// 		return Promise.resolve();
	// 	});

	render() {
		return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: userActions.logoutUser,
			setUserData: userActions.setUserData,
			setUserDataAuth0: userActions.setUserDataAuth0,
			setUserDataFirebase: userActions.setUserDataFirebase,
			showMessage: Actions.showMessage,
			hideMessage: Actions.hideMessage
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
//import PoweredByLinks from 'app/fuse-layouts/shared-components/PoweredByLinks';
//import PurchaseButton from 'app/fuse-layouts/shared-components/PurchaseButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18n';
const t = (...args) => i18n.t(...args);

function FooterLayout1(props) {
	const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { defaultMatches: true	});
	
	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar
				id="fuse-footer"
				className="relative z-10"
				color="default"
				style={{ backgroundColor: footerTheme.palette.background.default }}
			>
				<Toolbar className="px-16 py-0 flex items-center">
					<div className="flex flex-1">
						{/* <PurchaseButton /> */}
						{
						  /* eslint-disable no-useless-concat */
						  t("MyPass Srl") + " - " + "©" + " " + t("All Rights Reserved") + (isMobile ? "" : (" - " + t("P.Iva") + " " + "06455440484"))
						}
					</div>

					<div>
						{/* <PoweredByLinks /> */}
						<a href="https://www.mypass.cc">
							<img className="w-24" src="assets/images/logos/logo.png" alt="logo" />
						</a>
					</div>

					{/* Active Breakpoint Indicator */}
					{(process.env.NODE_ENV !== 'production') && (
						<div className="fixed bottom-0 right-0 mb-16 mr-56 p-3 text-xs font-mono text-white h-28 w-28 rounded-full flex items-center justify-center bg-gray-700 sm:bg-pink-500 md:bg-orange-500 lg:bg-green-500 xl:bg-blue-500">
							<div className="block  sm:hidden md:hidden lg:hidden xl:hidden">al</div>
							<div className="hidden sm:block  md:hidden lg:hidden xl:hidden">sm</div>
							<div className="hidden sm:hidden md:block  lg:hidden xl:hidden">md</div>
							<div className="hidden sm:hidden md:hidden lg:block  xl:hidden">lg</div>
							<div className="hidden sm:hidden md:hidden lg:hidden xl:block ">xl</div>
						</div>
					)}
					{/* /Active Breakpoint Indicator */}

				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default React.memo(FooterLayout1);

import React from 'react';
import { Redirect } from 'react-router-dom';

const AccountingConfig = {
	settings: {
		layout: {}
	},
	routes: [
    {
      path: '/sections/accounting/balance/:movementId/:tabId?',
      component: React.lazy(() => import('./movement/Movement'))
    },
		{
			path: '/sections/accounting/balance',
			component: React.lazy(() => import('./balance/Balance'))
		},
/*
		{
			path: '/sections/accounting/invoices',
			component: React.lazy(() => import('./invoices/Invoices'))
		},
		{
			path: '/sections/accounting/payments',
      component: React.lazy(() => import('./payments/Payments'))
		},
  */
		{
			path: '/sections/accounting',
			component: () => <Redirect to="/sections/accounting/balance" />
		}
 	]
};

export default AccountingConfig;

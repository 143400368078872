import { combineReducers } from 'redux';
import user from './user.reducer';
import login from './login.reducer';
import register from './register.reducer';
import forgotPassword from './forgotPassword.reducer';

const authReducers = combineReducers({
	user,
	login,
  register,
  forgotPassword,
});

export default authReducers;
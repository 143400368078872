import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from 'i18n-translations';
import config from 'config';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next 
	.use(LanguageDetector) // uses the browser's language
	.init({
		resources: translations,
		fallbackLng: config.fallbackLanguage,
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	})
;

export default i18n;
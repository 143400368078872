import React from 'react';
import { Redirect } from 'react-router-dom';

const ReservationsConfig = {
	settings: {
		layout: {}
	},
	routes: [ // order matters here...
    {
      path: '/sections/reservations/bookings/:bookingId/:bookingHandle?',
      component: React.lazy(() => import('./booking/Booking'))
    },
    {
      path: '/sections/reservations/bookings',
      component: React.lazy(() => import('./bookings/Bookings'))
    },
/*
		{
			path: '/sections/reservations/products/:productId/:productHandle?',
			component: React.lazy(() => import('./product/Product'))
		},
		{
			path: '/sections/reservations/products',
			component: React.lazy(() => import('./products/Products'))
		},
		{
			path: '/sections/reservations/orders/:orderId',
			component: React.lazy(() => import('./order/Order'))
		},
		{
			path: '/sections/reservations/orders',
			component: React.lazy(() => import('./orders/Orders'))
		},
  */
		{
			path: '/sections/reservations',
			component: () => <Redirect to="/sections/reservations/bookings" />
		}
 	]
};

export default ReservationsConfig;
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import jwtMypassService from 'app/services/jwtMypassService';
import * as Actions from 'app/store/actions';
import * as UserActions from './user.actions';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SEND_RESET_PASSWORD_EMAIL_ERROR = 'SEND_RESET_EMAIL_ERROR';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_RESET_EMAIL_SUCCESS';

export function submitMypassLogin({ email, password }) {
  //console.warn('submitMypassLogin');
	return dispatch =>
		jwtMypassService
			.signInWithEmailAndPassword(email, password)
			.then(user => {
				dispatch(UserActions.setUserData(user));

				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
			.catch(error => {
				return dispatch({
					type: LOGIN_ERROR,
					payload: error
				});
			})
	;
}

export function submitLogin({ email, password }) {
	return dispatch =>
		jwtService
			.signInWithEmailAndPassword(email, password)
			.then(user => {
				dispatch(UserActions.setUserData(user));

				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
			.catch(error => {
				return dispatch({
					type: LOGIN_ERROR,
					payload: error
				});
      })
  ;
}

export function submitLoginWithFireBase({ username, password }) {
	if (!firebaseService.auth) {
		console.warn("Firebase Service didn't initialize, check your configuration");

		return () => false;
	}

	return dispatch =>
		firebaseService.auth
			.signInWithEmailAndPassword(username, password)
			.then(() => {
				return dispatch({
					type: LOGIN_SUCCESS
				});
			})
			.catch(error => {
				console.info('error');
				const usernameErrorCodes = [
					'auth/email-already-in-use',
					'auth/invalid-email',
					'auth/operation-not-allowed',
					'auth/user-not-found',
					'auth/user-disabled'
				];
				const passwordErrorCodes = ['auth/weak-password', 'auth/wrong-password'];

				const response = {
					username: usernameErrorCodes.includes(error.code) ? error.message : null,
					password: passwordErrorCodes.includes(error.code) ? error.message : null
				};

				if (error.code === 'auth/invalid-api-key') {
					dispatch(Actions.showMessage({ message: error.message }));
				}

				return dispatch({
					type: LOGIN_ERROR,
					payload: response
				})
      })
  ;
}

/**
 * sendResetPasswordEmail
 */
export function sendResetPasswordEmail(email) {
  return dispatch =>
    jwtMypassService
      .sendResetPasswordEmail(email)
      .then(user => {
        return dispatch({
          type: SEND_RESET_PASSWORD_EMAIL_SUCCESS
        });
      })
      .catch(error => {
        return dispatch({
          type: SEND_RESET_PASSWORD_EMAIL_ERROR,
          payload: error
        });
      })
    ;
}
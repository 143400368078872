import React from 'react';
import { Redirect } from 'react-router-dom';

const ConciergeConfig = {
	settings: {
		layout: {}
	},
	routes: [
    {
      path: '/sections/concierge/event/:movementId/:tabId?',
      component: React.lazy(() => import('./movement/Movement'))
    },
		{
			path: '/sections/concierge/event',
			component: React.lazy(() => import('./event/Event'))
		},
/*
		{
			path: '/sections/concierge/invoices',
			component: React.lazy(() => import('./invoices/Invoices'))
		},
		{
			path: '/sections/concierge/payments',
      component: React.lazy(() => import('./payments/Payments'))
		},
  */
		{
			path: '/sections/concierge',
			component: () => <Redirect to="/sections/concierge/event" />
		}
 	]
};

export default ConciergeConfig;

// import i18next from 'i18next';
import React from 'react';
import { Redirect } from 'react-router-dom';
// import en from './i18n/en';
// import it from './i18n/it';
// import ar from './i18n/ar';

//i18next.addResourceBundle('en', 'mailApp', en);
//i18next.addResourceBundle('it', 'mailApp', it);
//i18next.addResourceBundle('ar', 'mailApp', ar);

const MailAppConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: [
				'/apps/mail/label/:labelHandle/:mailId?',
				'/apps/mail/filter/:filterHandle/:mailId?',
				'/apps/mail/:folderHandle/:mailId?'
			],
			component: React.lazy(() => import('./MailApp'))
		},
		{
			path: '/apps/mail',
			component: () => <Redirect to="/apps/mail/inbox" />
		}
	]
};

export default MailAppConfig;

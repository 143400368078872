export const HIDE_MESSAGE = '[MESSAGE] CLOSE';
export const SHOW_MESSAGE = '[MESSAGE] SHOW';

export function hideMessage() {
	return {
		type: HIDE_MESSAGE
	};
}

export function showMessage(options) {
  // force an auto-hide default value for messages
  options.autoHideDuration = options.autoHideDuration >= 0 ? options.autoHideDuration : 3 * 1000; // ms
	return {
		type: SHOW_MESSAGE,
		options
	};
}

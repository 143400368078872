//import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import FuseSeparator from '@fuse/core/FuseSeparator';
//import ExpandLess from '@material-ui/icons/ExpandLess';
//import ExpandMore from '@material-ui/icons/ExpandMore';
//import StoreIcon from '@material-ui/icons/Store';
//import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import * as authActions from 'app/auth/store/actions';
import * as Actions from 'app/store/actions';
import NetworkService from 'app/services/networkService';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function UserMenu(props) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const [userMenu, setUserMenu] = useState(null);
  
	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

  const userMerchantOpenHandleClick = (roleIndex) => {
		localStorage.setItem('currentRoleIndex', roleIndex); // save current role index to local storage
    dispatch(Actions.setDefaultSettings({ currentRoleIndex: roleIndex }));
    //const role = user.roles[roleIndex].role;
		//console.log("userMerchantOpenHandleClick user.roles:", user.roles);
		//console.log("userMerchantOpenHandleClick role:", role);
    //dispatch(authActions.setUserData({ ...user, role/*: "staff"*/ })); // change user's role (!!!) TODO: get role from a map of user.roles[currentRoleIndex].role
		userMenuClose();
	};

	const { t } = useTranslation();

	const info = {
		name: require('../../../../package.json').name,
		version: require('../../../../package.json').version,
		where: NetworkService.isLocal() ? t("local service") : t("public service"),
		how: NetworkService.isProduction() ? t("production") : t("test"),
		APIUrl: NetworkService.getAPIUrl(''),
	};

 	const handleAbout = () => {
		dispatch(
			Actions.openDialog({
				children: (
					<>
						<DialogTitle id="alert-dialog-title">
							{t('About this app')}
							<hr></hr>
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<>
									{t('The name of this app is')} "<b>{info.name}</b>"<br />
								  {t('The version number is')} <b>{info.version}</b><br />
								  {t('The running server is')} <b>{info.where}</b><br />
								  {t('The mode is')} <b>{info.how}</b><br />
									{(process.env.NODE_ENV === 'development') && t('The API endpoint is')} <b>{info.APIUrl}</b><br />
								</>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => {dispatch(Actions.closeDialog()); userMenuClose() }} color="secondary">
								Close
							</Button>
						</DialogActions>
					</>
				)
			})
		);
  };
  
	return (
		<>
			<Button className="h-64" onClick={userMenuClick}>
				{user.data.photoURL ? (
					<Avatar className="" alt="user photo" src={user.data.photoURL} />
				) : (
					<Avatar className="">{user.data.displayName[0]}</Avatar>
				)}

				<div className="hidden md:flex flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex">
						{user.data.displayName}
					</Typography>
					<Typography className="text-11 capitalize" color="textSecondary">
						{user.role.toString()}
					</Typography>
				</div>

				<Icon className="text-16 hidden sm:flex" variant="action">
					keyboard_arrow_down
				</Icon>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				<>
					{!user.role || user.role.length === 0 ? (
						<>
							<MenuItem component={Link} to="/login" role="button">
								<ListItemIcon className="min-w-40">
									<Icon>lock</Icon>
								</ListItemIcon>
								<ListItemText primary={t('Login')} />
							</MenuItem>
							<MenuItem component={Link} to="/register" role="button" disabled>
								<ListItemIcon className="min-w-40">
									<Icon>person_add</Icon>
								</ListItemIcon>
								<ListItemText primary={t('Register')} />
							</MenuItem>
						</>
					) : (
						<>
							{user.roles && user.roles.map((role, index) => (
                <MenuItem key={index} component={Link} to="/" to_orig="sections/company/info" onClick={() => userMerchantOpenHandleClick(index)} role="button">
									<ListItemIcon className="min-w-40">
										<Icon>business</Icon>
									</ListItemIcon>
									<ListItemText
										disableTypography
										primary={
											<Typography type="body2" style={{ fontWeight: 'normal' }}>
												{role.merchant_name}
                        {role.event_description && <FuseSeparator />}
												{role.event_description}
											</Typography>
										}
									></ListItemText>
								</MenuItem>
							))}
              {(user.role && /*(*/user.role === 'admin'/* || user.role === 'staff')*/) ? (
                <>
                    <MenuItem key={'+'} component={Link} to="/sections/company/create" onClick={() => userMenuClose()} role="button">
                    <ListItemIcon className="min-w-40">
                      <Icon>business</Icon>
                    </ListItemIcon>
                    <ListItemIcon className="min-w-40">
                      <Icon>add</Icon>
                    </ListItemIcon>
                    <Typography style={{ fontStyle: 'italic' }}>
                      {t("create new company (only for admin users)")}
                    </Typography>
                  </MenuItem>
                </>
              ) : null}
              <Divider />
              <MenuItem component={Link} to="/company/profile" onClick={userMenuClose} role="button" disabled>
                <ListItemIcon className="min-w-40">
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText primary={t('My Profile')} />
              </MenuItem>
              {/*
              <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">
								<ListItemIcon className="min-w-40">
									<Icon>mail</Icon>
								</ListItemIcon>
								<ListItemText primary={t('Inbox')} />
              </MenuItem>
              */}
							<MenuItem
								onClick={() => {
									dispatch(authActions.logoutUser());
									userMenuClose();
								}}
							>
								<ListItemIcon className="min-w-40">
									<Icon>exit_to_app</Icon>
								</ListItemIcon>
								<ListItemText primary={t('Logout')} />
							</MenuItem>
						</>
					)}
					<MenuItem onClick={handleAbout} role="button">
						<ListItemIcon className="min-w-40">
							<Icon>info</Icon>
						</ListItemIcon>
						<ListItemText primary={t('About') + ' (' + info.version + ')'} />
					</MenuItem>
				</>
			</Popover>
		</>
	);
}

export default UserMenu;